import { motion } from "framer-motion";

function FlipImage({ img }) {
  return (
    <motion.img
      transition={{
        duration: 5.5,
      }}
      animate={{ x: [0, 200, 200, 0], y: [0, 100, 0, 0] }}
      src={img}
      className="max-h-[400px] lg:max-h-[550px] w-[200px] sm:w-[200px] md:w-[300px] lg:max-w-[450px] object-contain min-h-full"
    />
  );
}

export default FlipImage;
