import React from "react";
import Background from "../asset/File/pc.png";
import Typewriter from "typewriter-effect";
import Down from "../asset/down.png";
import { motion } from "framer-motion";
import NavBar from "./NavBar";

const Header = ({ header, executeScroll, expRef, workRef, contactRef }) => {
  return (
    <div
      ref={header}
      className="flex flex-col w-[100%] justify-center items-center"
    >
      <NavBar
        header={header}
        executeScroll={executeScroll}
        expRef={expRef}
        workRef={workRef}
        contactRef={contactRef}
      />

      <motion.div
        initial={{
          x: +500,
          opacity: 0,
        }}
        animate={{ x: 0, opacity: 1 }}
        transition={{
          duration: 1.5,
        }}
        className="flex flex-col min-h-[80vh] justify-center items-center"
      >
        <div className="text-[#3f1429] font-[1000] font-mono text-[30px] sm:text-[40px] md:text-[50px] lg:text-[80px] xl:text-[100px]">
          <Typewriter
            options={{
              strings: ["BINYAM EDMEALEM"],
              autoStart: true,
              loop: true,
            }}
          />
        </div>
        <p className="text-[#3f1429] font-semibold font-mono text-center text-[15px] sm:text-[18px] md:text-[20px] lg:text-[25px]">
          FULL STACK WEBSITE AND MOBILE APP DEVELOPER
        </p>
        <img
          className="w-[90%] md:w-[50%] my-10"
          src={Background}
          onClick={() => executeScroll(expRef)}
        ></img>
      </motion.div>

      <div className="relative">
        <div className="absolute -inset-0.5 bg-[#3f1429] blur rounded-lg"></div>
        <img
          src={Down}
          onClick={() => executeScroll(expRef)}
          className="cursor-pointer animate-bounce bg-[#3f1429] border-white border-2 rounded-md p-2"
          style={{ width: 80, height: 80 }}
        ></img>
      </div>
    </div>
  );
};

export default Header;
