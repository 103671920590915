import React, { forwardRef } from "react";
import Phone from "../asset/Gossip/phone.png";
import Work from "../data/project.json";

import FlipImage from "./FlipImage";
import { Link } from "react-router-dom";

const Works = forwardRef(({ onBackClick }, ref) => {
  return (
    <div
      ref={ref}
      onClick={onBackClick}
      className="w-[90%] lg:w-[70%] min-h-screen ml-auto mr-auto"
    >
      <div className="flex justify-evenly items-center">
        <h2 className="text-center font-semibold text-[#3f1429] font-mono text-[25px] sm:text-[30px] md:text-[50px] lg:text-[80px]">
          My Work
        </h2>

        <FlipImage img={Phone} />
      </div>
      <div className="flex flex-col  md:flex-row md:flex-wrap items-center md:items-start md:justify-between">
        {Work?.project?.map((item, index) => (
          <Link
            to={`/detail/${item.id}`}
            className={`shadow-md border-[#f8dff8] border-[1px] scale-100 hover:border-purple-400 hover:border-[1px] hover:shadow-amber-800 hover:scale-110 duration-1000 w-[90%] md:w-[30%] md:h-[250px] p-2 bg-[#f3f3f3] my-10 rounded-lg`}
          >
            <img className="md:h-[90%] object-contain" src={item.img}></img>
            <p className="font-semibold font-mono text-center my-2 text-[14px] sm:text-[16px]md:text-[18px] ">
              {item.title}
            </p>
          </Link>
        ))}
      </div>
    </div>
  );
});

export default Works;
