import React, { useRef } from "react";
import ReactImg from "../asset/react.png";
import ReactNative from "../asset/RN.png";
import Node from "../asset/node.png";
import MySQL from "../asset/mysql.png";
import GitHub from "../asset/github.png";
import AWS from "../asset/aws.png";
import { forwardRef } from "react";
import { motion } from "framer-motion";

const Expertise = forwardRef(({ onBackClick }, ref) => {
  const expertise = [
    {
      id: 1,
      name: "Frontend Dev, React",
      image: ReactImg,
      description:
        "Experienced in developing websites using React JS library, React-Bootstrap, Tailwind CSS, Redux, React-Router.",
    },
    {
      id: 2,
      name: "Backend Dev, Node JS",
      image: Node,
      description:
        "Skilled in developing fast, scalable ,and realtime backend using Node JS and Express.",
    },
    {
      id: 3,
      name: "Cross-Platform Mobile App, React Native",
      image: ReactNative,
      description:
        "Develop cross-platform mobile application using React Native (both expo and bare react native), notification using FCM.",
    },
    {
      id: 4,
      name: "Database, MySQL",
      image: MySQL,
      description:
        "Experienced in developing relational database using MySQL and non-relational database like MongoDB.",
    },
    {
      id: 5,
      name: "Version Control",
      image: GitHub,
      description:
        "Git for version control. GitHub and BitBucket for remote repository.",
    },
    {
      id: 6,
      name: "Server Deployment",
      image: AWS,
      description:
        "Use AWS EC2, digitalocean droplet or other cloud service provider platform for deployment, server configuration, and backup.",
    },
  ];
  return (
    <div ref={ref} onClick={onBackClick} className="mt-[100px] min-h-screen">
      <h2 className="text-center font-semibold text-[#3f1429] font-mono text-[18px] sm:text-[25px] md:text-[30px] lg:text-[50px]">
        My Expertise
      </h2>
      <div className="p-5 flex flex-col lg:flex-row xl:w-[80%] justify-center items-center w-[100%] ml-auto mr-auto flex-wrap">
        {expertise.map((item, index) => (
          <motion.div
            whileInView="visible"
            viewport={{ once: true }}
            initial={{
              x: index == 0 ? -500 : index == 2 ? -500 : +500,
              opacity: 0,
            }}
            animate={{ x: 0, opacity: 1 }}
            transition={{
              duration: 1.5,
            }}
            className="w-[100%] bg-[whitesmoke] rounded-md flex-col lg:w-[30%] p-5 items-start flex min-w-[150px] sm:min-w-[250px] border-[#3f1429] border-2 lg:h-[400px] m-1 "
          >
            <div className="flex flex-row justify-center items-center">
              <img src={item.image} style={{ width: 100, height: 80 }}></img>
              <p className="text-[#3f1429] font-semibold text-[18px] sm:text-[20px] mx-2">
                {item.name}
              </p>
            </div>
            <div className=" m-2">
              <p className="text-[#422031]">{"<h3>"}</p>
              <p className="text-[#3f1429] text-[15px] font-mono px-2 border-l-[#3f1429] border-l-4 sm:text-[18px]">
                {item.description}
              </p>
              <p className="text-[#422031]">{"<h3>"}</p>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
});

export default Expertise;
